import React from 'react';
import HeaderTwo from "../../common/header/HeaderTwo";
import FooterTwo from "../../common/footer/FooterTwo";
import Copyright from '../../common/footer/Copyright';

import SectionTitle from "../sectionTitle/SectionTitle";
import GalleryOne from "./GalleryOne";
import {useTranslation} from "react-i18next";


const PopupData2 = [
    {
        id: "01",
        image: "./images/portfolio/portfolio-01.png",
        popupLink: [
            './images/portfolio/portfolio-01-en.png',
        ],
    },
    {
        id: "02",
        image: "./images/portfolio/portfolio-02.png",
        popupLink: [
            './images/portfolio/portfolio-02-en.png',
        ],
    },
    {
        id: "03",
        image: "./images/portfolio/portfolio-03.png",
        popupLink: [
            './images/portfolio/portfolio-03-en.png',
        ],
    },
    {
        id: "04",
        image: "./images/portfolio/portfolio-04.png",
        popupLink: [
            './images/portfolio/portfolio-04-en.png',
        ],
    },
    {
        id: "05",
        image: "./images/portfolio/portfolio-05.png",
        popupLink: [
            './images/portfolio/portfolio-05-en.png',
        ],
    },
    {
        id: "06",
        image: "./images/portfolio/portfolio-06.png",
        popupLink: [
            './images/portfolio/portfolio-06-en.png',
        ],
    },
    {
        id: "07",
        image: "./images/portfolio/portfolio-07.png",
        popupLink: [
            './images/portfolio/portfolio-07-en.png',
        ],
    },
    {
        id: "08",
        image: "./images/portfolio/portfolio-08.png",
        popupLink: [
            './images/portfolio/portfolio-08-en.png',
        ],
    },
    {
        id: "09",
        image: "./images/portfolio/portfolio-09.png",
        popupLink: [
            './images/portfolio/portfolio-09-en.png',
        ],
    },
    {
        id: "10",
        image: "./images/portfolio/portfolio-10.png",
        popupLink: [
            './images/portfolio/portfolio-10-en.png',
        ],
    },
    {
        id: "11",
        image: "./images/portfolio/portfolio-11.png",
        popupLink: [
            './images/portfolio/portfolio-11-en.png',
        ],
    },
    {
        id: "12",
        image: "./images/portfolio/portfolio-12.png",
        popupLink: [
            './images/portfolio/portfolio-12-en.png',
        ],
    },
    {
        id: "13",
        image: "./images/portfolio/portfolio-13.png",
        popupLink: [
            './images/portfolio/portfolio-13-en.png',
        ],
    },
    {
        id: "14",
        image: "./images/portfolio/portfolio-14.png",
        popupLink: [
            './images/portfolio/portfolio-14-en.png',
        ],
    },
    {
        id: "16",
        image: "./images/portfolio/portfolio-16.png",
        popupLink: [
            './images/portfolio/portfolio-16-en.png',
        ],
    },
    {
        id: "17",
        image: "./images/portfolio/portfolio-17.png",
        popupLink: [
            './images/portfolio/portfolio-17-en.png',
        ],
    },
]
const PopupData3 = [
    {
        id: "01",
        image: "./images/portfolio/portfolio-01-.png",
        popupLink: [
            './images/portfolio/portfolio-01-es.png',
        ],
    },
    {
        id: "02",
        image: "./images/portfolio/portfolio-02-.png",
        popupLink: [
            './images/portfolio/portfolio-02-es.png',
        ],
    },
    {
        id: "03",
        image: "./images/portfolio/portfolio-03-.png",
        popupLink: [
            './images/portfolio/portfolio-03-es.png',
        ],
    },
    {
        id: "04",
        image: "./images/portfolio/portfolio-04-.png",
        popupLink: [
            './images/portfolio/portfolio-04-es.png',
        ],
    },
    {
        id: "05",
        image: "./images/portfolio/portfolio-05-.png",
        popupLink: [
            './images/portfolio/portfolio-05-es.png',
        ],
    },
    {
        id: "06",
        image: "./images/portfolio/portfolio-06-.png",
        popupLink: [
            './images/portfolio/portfolio-06-es.png',
        ],
    },
    {
        id: "07",
        image: "./images/portfolio/portfolio-07-.png",
        popupLink: [
            './images/portfolio/portfolio-07-es.png',
        ],
    },
    {
        id: "08",
        image: "./images/portfolio/portfolio-08-.png",
        popupLink: [
            './images/portfolio/portfolio-08-es.png',
        ],
    },
    {
        id: "09",
        image: "./images/portfolio/portfolio-09-.png",
        popupLink: [
            './images/portfolio/portfolio-09-es.png',
        ],
    },
    {
        id: "10",
        image: "./images/portfolio/portfolio-10-.png",
        popupLink: [
            './images/portfolio/portfolio-10-es.png',
        ],
    },
    {
        id: "11",
        image: "./images/portfolio/portfolio-11-.png",
        popupLink: [
            './images/portfolio/portfolio-11-es.png',
        ],
    },
    {
        id: "12",
        image: "./images/portfolio/portfolio-12-.png",
        popupLink: [
            './images/portfolio/portfolio-12-es.png',
        ],
    },
    {
        id: "13",
        image: "./images/portfolio/portfolio-13-.png",
        popupLink: [
            './images/portfolio/portfolio-13-es.png',
        ],
    },
    {
        id: "14",
        image: "./images/portfolio/portfolio-14-.png",
        popupLink: [
            './images/portfolio/portfolio-14-es.png',
        ],
    },
    {
        id: "16",
        image: "./images/portfolio/portfolio-16-.png",
        popupLink: [
            './images/portfolio/portfolio-16-es.png',
        ],
    },
    {
        id: "17",
        image: "./images/portfolio/portfolio-17-.png",
        popupLink: [
            './images/portfolio/portfolio-17-es.png',
        ],
    }
]

const Elements = () => {
    const {t, i18n} = useTranslation();
    return (
        <>

                <div className="main-content">
                <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

                    {/* Start Elements Area  */}
                    <div className="rwt-gallery-area rn-section-gapBottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = {t("know")}
                                        title = {t("products-title")}
                                        description = {t("products-subtitle")}
                                    />
                                </div>
                            </div>
                            <div className="row mt_dec--30 row--15">
                                {i18n.language === "en" ? PopupData2.map((item) => (
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                )) : PopupData3.map((item) => (
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area  */}

                    <FooterTwo />
                <Copyright />
                </div>

        </>
    )
}
export default Elements;